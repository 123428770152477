<template>
<ion-page id="main-content">

    <ion-content>
        <img src="/assets/images/splash.png" style="object-fit: cover;width:100%;">
        <div class="ion-padding">
            <h1 class="first-text fontapp" style="font-size:46px;color:#c3c3c3"> Bienvenue </h1>
            <br>
            <ion-row>
                <ion-col size="12">
                    <center>
                        <ion-card @click="localIP()" class="ion-button2" style=" width:100%;height:70px;padding:10px;background-color:#ed242c">
                            <center style=" margin-top:12px">
                                <span class="first-text fontapp" style="font-size:20px;color:#141414">LOGIN</span>
                            </center>
                        </ion-card>
                    </center>
                </ion-col>

            </ion-row>
        </div>
    </ion-content>
    <ion-footer>
        <ion-toolbar>
            
            <ion-title class="" style="background-color:#0e0e0f; position: relative;left: 0px;"> 
                <a href="https://facebook.com/bgrsmart" style="text-decoration: none;">
                <img src="/assets/images/bgr.png" style="width:10%;">
                <span style="color:#636363;font-size:20px; position: relative;top: -25px;">By BGR SMART SOLUTION ©</span>
            </a>
            </ion-title>    
        </ion-toolbar>
    </ion-footer>
</ion-page>
</template>

<script>
import {toastController} from '@ionic/vue';
import {ipServerObjet as ipServerObjet} from './Configserver.js';
import {

    IonContent,
    IonPage,
    IonCard,
    IonCol,
    IonRow,
    IonFooter,
    IonTitle,
    IonToolbar
} from '@ionic/vue';

export default {
    data() {
        return {
            data: '',
            ip: '',
            ID_RFID:'',
        }
    },
    methods: { 
        async NoConnexion() {
            const toast = await toastController.create({
                message: 'Oups ! Il semble y avoir un problème avec votre connexion Internet',
                duration: 2000,
                position: 'bottom',
                color: 'danger',
                mode: 'ios'
            });
            await toast.present();
        },      

        localIP() {
            for (const property in ipServerObjet) {
                this.ipServer = ipServerObjet[property].ip;
            }

                this.$store.dispatch('addip', this.ipServer);
                if (localStorage.getItem('carte') !== null) {
                    this.ID_RFID = localStorage.getItem('carte');

            this.axios({
                url: ''+this.ipServer+'/api/CLIENT_PSW_EXIST_BY_RFID/' + this.ID_RFID,
                method: 'get',
                timeout: 15000,
            }).then(response => {

                if (response.data != '') {

          
                this.axios({
                    url: ''+this.ipServer+'/api/UPDATE_DATE_DERNIER_ACCESS/' + this.ID_RFID,
                    method: 'get',
                    timeout: 15000,
                }).catch(async () => {
                this.NoConnexion();
            });

            this.$router.push('/accuiel');



                }else{
                    this.DeleteStoreg();
                    this.$router.push('/LoginAdmin', 'forward', 'replace');
                }


            })
            .catch(async () => {
                console.log();
                this.NoConnexion();
            });
                    
                } else {
                    this.$router.push('/LoginAdmin', 'forward', 'replace');

                }

            
        },
        DeleteStoreg() {
            localStorage.removeItem('carte');
            this.$router.push('/');
        }

    }
    ,
    created() {

    },

    components: {

        IonContent,
        IonCard,
        IonCol,
        IonRow,
        IonPage,
        IonFooter,
        IonTitle,
        IonToolbar

    }
}
</script>

<style>
.custom-toast {
    --background: #F4F4FA;
    --box-shadow: 3px 3px 10px 0 rgba(0, 0, 0, 0.2);
    --color: #4b4a50;
}

.custom-toast::part(message) {
    font-style: italic;
}

.custom-toast::part(button) {
    border-left: 1px solid #d2d2d2;
    color: #030207;
    font-size: 15px;
}

.container {
    display: flex;
    overflow-x: auto;
}

ion-card {
    border-radius: 15px;
}

ion-content {

    --ion-background-color: #070707;
}

.ion-padding2 {
    --ion-background-color: #151516;

}

.ion-button1 {
    --background: #e5ff00;
    --opacity: 0.8;
    height: 30px;
    color: #2D2D30;
    font-weight: 700;
    padding: 0px;
    --border-radius: 10px !important;
}

.ion-button2 {
    --background: #1C1C1C;
    --opacity: 0.8;
    height: 30px;
    padding: 0px;
    --border-radius: 10px !important;
}

/* Let's get this party started */
::-webkit-scrollbar {
    width: 0px;
}
</style>
